import React, { useEffect, useState, useRef } from "react";
import Jobin from 'jobin-client'
import { useStateValue } from "../../state/context";
import { Button } from "jobin-ui-components";
import ChatModal from "../modal/ChatModal"
import { Loading } from 'jobin-ui-components'
import {setReaded} from '../../utils/ParserUtilities'
import  CSVExport  from "../../utils/csvExport"

import "../../styles/search/result-table.css";
import Switch from "../singleComponents/switch";

export default function ResultTable(props) {

    const [{user, userSearch }, dispatch] = useStateValue();
    const [currentChat,setCurrentChat] = useState([])
    const [currentUser,setCurrentUser] = useState()
    const [showChatModal,setShowChatModal] = useState(false)

    const csvRef = useRef(null);

    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        },
        tableContainer: {
        },
        userPic:{
            height: 42,
            width: 42,
            borderRadius: 21
        },
        medalPic:{
            marginLeft: -14,
            marginBottom: -6
        },
        starPic:{
            height: 14,
            width: 14,
            marginLeft: 5
        }
    };
    

    const itemDetail = id => {
        props.history.push({
            pathname: "/user/" + id,
            state: {
                inner: true
            }
        });
    };
    const showModal = (_chats)=>{
        setShowChatModal(true)
    }
    const checkMessages = (_chats) =>{
        var newMessages = 0
        _chats.map((message)=>{
            if(!message.read && message.user){
                newMessages+=1
            }
        })
        return newMessages
    }
    const checkCanSeePC = (value) =>{
        const canSeePCOptions = [
            {label:"No puede", value:false},
            {label:"Puede (WollyPRO 2.0)", value:true},
            {label:"Sin definir (no puede)", value: undefined},
        ]

        return canSeePCOptions.find(option => option.value === value).label;
    }

    const closeModal = (data)=>{
    
        var users = userSearch.items.map((item)=>{
            if(item.user.id === data.userId){
                item.chat = data.chat
            }
            return item
        })
        dispatch({
            type: "USER_SEARCH",
            items: users
        });
        setShowChatModal(false)
        
    }

    const getCSVInfo = () => {
        let csvData = {
            data: [],
            headers: [
                { label: "Nombre", key: "name" }, { label: "Teléfono", key: "phone" },
                { label: "Nº Reviews", key: "reviews" }, { label: "Puntuación", key: "points" },
                { label: "Créditos", key: "credits" }, { label: "Nº de compras", key: "buy" },
                { label: "Última conexión", key: "lastConexion" }, { label: "Fecha registro", key: "registerDate" },   
            ]
        }

        userSearch.items.map( item => {
            csvData.data.push( {
                name: item.user.get("Name"), 
                phone: item.user.get("Phone"),
                reviews: item.user.get("Reviews"),
                points: item.user.get("Points") ? (item.user.get("Points")).toFixed(2) : 0,
                credits: item.user.get("nJobs"),
                buy: item.user.get("nPayments") ? item.user.get("nPayments") : 0,
                lastConexion: item.user.get("Updated") ? Jobin.Date.toSortFormat(item.user.get("Updated")).result : "-",
                registerDate: item.user.get("createdAt") ? Jobin.Date.toSortFormat(item.user.get("createdAt")).result:null
            });
        })

        return csvData;
    }

    const JoberTable = key => {
        return (
            <div>
                <ChatModal isVisible={showChatModal} chat={currentChat} user={currentUser} onClose={(a)=> closeModal(a)}/>
                {
                    user.permissions.changeWollerActive && (
                        <div className={"download-users"}>
                            <a ref={csvRef} style={{ display: "none" }} />
                            <CSVExport
                                children={"Descargar"}
                                getDataForCsv={getCSVInfo}
                            />
                        </div>
                    )
                }
                <table>
                    <thead>
                        <tr>
                            {userSearch.itemsTitle.map((title, index) => {
                                return (
                                    <th key={`${key}-${title}-${index}`}>
                                        {title}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {userSearch.items.map((item, index) => {
                            return (
                                <tr key={`${key}-${item.user.id}-${index}`}>
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                        <div>
                                            <img
                                                style={style.userPic}
                                                src={item.user.get("UserPic") ? item.user.get("UserPic").url() : require("../../assets/user-default-pic.svg")}
                                                alt="userPic"
                                            />
                                            {item.user.Level === 2 ? (
                                                <img
                                                    style={style.medalPic}
                                                    src={require("../../assets/badge-silver.svg")}
                                                    alt="Plata"
                                                />
                                            ) : item.user.Level === 3 ? (
                                                <img
                                                    style={style.medalPic}
                                                    src={require("../../assets/badge-gold.svg")}
                                                    alt="Oro"
                                                />
                                            ) : item.user.Level === 4 ? (
                                                <img
                                                    style={style.medalPic}
                                                    src={require("../../assets/badge-platinum.svg")}
                                                    alt="Platino"
                                                />
                                            ) : (
                                                <img
                                                    style={style.medalPic}
                                                    src={require("../../assets/badge-bronce.svg")}
                                                    alt="Bronce"
                                                />
                                            )}
                                        </div>
                                    </td>
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                        {item.user.get("Name")}
                                    </td>
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                        {item.user.get("Phone")}
                                    </td>
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                        {item.user.get("Reviews")}
                                    </td>
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                        <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                                            {item.user.get("Points") ? (item.user.get("Points")).toFixed(2) : 0}
                                            <img style={style.starPic} src={require("../../assets/star-fill.svg")}/>
                                        </div>
                                    </td>

                                    {!user?.isGoldWoller && !user?.isConstructionCompany && (
                                        <td onClick={() => window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                            {item.user.get("nJobs") + " €"}
                                        </td>
                                    )}

                                        < td onClick = {()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                    {item.user.get("nPayments") ? item.user.get("nPayments") : 0}
                                    </td>
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                        {item.user.get("Updated") ? Jobin.Date.toSortFormat(item.user.get("Updated")).result : "-"}
                                    </td>
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")}>
                                        {item.user.get("createdAt") ? Jobin.Date.toSortFormat(item.user.get("createdAt")).result:null}
                                    </td>
                                    {!user?.isGoldWoller && !user?.isConstructionCompany && ( <td>
                                        {checkCanSeePC(item.user.get("canSeePC"))}
                                    </td>)}
                                   {!user?.isGoldWoller && !user?.isConstructionCompany && ( <td>
                                        <Button
                                            style={{marginTop:"5px", height: 36, padding: 0, width: 110, backgroundColor: "#000000", borderColor: "#000000", color: "#FFE235" }}
                                            buttonText={checkMessages(item.chat) < 1 ?  "Chat":"+"+checkMessages(item.chat)}
                                            className = {checkMessages(item.chat) < 1 ? "noMessages":"newMessage"}
                                            onClick={ ()=>{showModal();  setCurrentChat(item.chat); setCurrentUser(item.user); setReaded(item.user.id)}}
                                        />
                                    </td>)}
                                    <td onClick={()=> window.open(`${window.location.href}/${item.user.id}`, "_blank")} style={{display: "flex", justifyContent: "center"}}>
                                        {
                                            item.user.get("Business")?.get("Logo")?._url && (
                                                <img style={{height: 70, width: 90}} src={item.user.get("Business")?.get("Logo")._url} />
                                            )
                                        }
                                    </td>                         
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const UserTable = key => {
        return (
            <table>
                <thead>
                    <tr>
                        {userSearch.itemsTitle.map((title, index) => {
                            return (
                                <th key={`${key}-${title}-${index}`}>
                                    {title}
                                </th>
                            );
                        })}
                        <th key={`business`}>
                            {'Business'}
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {userSearch.items.map((item, index) => {
                     
                        return (
                            <tr key={`${key}-${item.id}-${index}`}>
                                <td onClick={() => itemDetail(item.id)}>
                                    <div>
                                        <img
                                            style={style.userPic}
                                            src={item?.get("UserPic") ? item.get("UserPic").url() : require("../../assets/user-default-pic.svg")}
                                            alt="UserPic"
                                        />
                                        {item?.get("Level") === 2 ? (
                                            <img
                                                style={style.medalPic}
                                                src={require("../../assets/badge-silver.svg")}
                                                alt="Plata"
                                            />
                                        ) : item?.get("Level") === 3 ? (
                                            <img
                                                style={style.medalPic}
                                                src={require("../../assets/badge-gold.svg")}
                                                alt="Oro"
                                            />
                                        ) : item?.get("Level") === 4 ? (
                                            <img
                                                style={style.medalPic}
                                                src={require("../../assets/badge-platinum.svg")}
                                                alt="Platino"
                                            />
                                        ) : (
                                            <img
                                                style={style.medalPic}
                                                src={require("../../assets/badge-bronce.svg")}
                                                alt="Bronce"
                                            />
                                        )}
                                    </div>
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item?.get("Name")}
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item?.get("Phone")}
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item?.get("Updated") ? Jobin.Date.toSortFormat(item.get("Updated")).result : "-"}
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {Jobin.Date.toSortFormat(item.get("createdAt")).result}
                                </td>
                                <td onClick={() => itemDetail(item.id)}>
                                    {item?.get("Business") ? <img src={ item.get("Business").get("Logo")?.url() } alt={item.get("Business").id} /> : null}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    if (userSearch.loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    } else {
        return (
            <div
                className="result-table-container"
                style={style.tableContainer}
            >
                {userSearch.items.length > 0 ? (
                    <div>
                        {userSearch.idType === "jober" ?
                            <JoberTable key="lead" /> :
                            <UserTable key="lead" />
                        }    
                    </div>
                ) : (
                    <section style={{ padding: 8 }}>
                        <h3
                            style={{
                                fontSize: 25,
                                marginTop: 0,
                                marginBottom: 8
                            }}
                        >
                            No se han encontrado resultados
                        </h3>
                        <p>
                            Modifica los filtros de tu búsqueda para ampliar el
                            rango.
                        </p>
                    </section>
                )}
            </div>
        );
    }
}
