import React, { useEffect,useState } from "react";
import Jobin from "jobin-client";
import { Loading } from "jobin-ui-components";
import { useStateValue } from "../../state/context";
import { searchWollerChat,searchWollerDocuments } from "../../utils/ParserUtilities";

import NavBar from "../../components/NavBar";
import Details from "../../components/user/detail/Details";
import UserCommets from "../../components/user/detail/UserComments";
import DetailsPro from "../../components/user/biPro/Details.jsx";
import Description from "../../components/user/biPro/Description.jsx";

import {getFullWollerProfile} from "../../utils/ParserUtilities"


import "../../styles/Users/usersDetails.css";
import "../../styles/search/detail/job-details.css";
import NearJobs from "../../components/user/detail/NearJobs";

export default function UserDetails(props) {
    const [user_,setUser_] = useState(null)
    const [userDetails_,setUserDetails_] = useState(null)
    const [tasks_,setTasks_] = useState(null)
    const [reviews_,setReviews_] = useState(null)
    const [{user,currentSession,general,userDetails }, dispatch] = useStateValue();
    const style = {
        loadingContainer: {
            display: "flex",
            flex: 1,
            backgroundColor: "var(--soft-grey)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }
    };

    useEffect(()=>{
        setUser_(userDetails.user)
        setUserDetails_(userDetails.wollerData)
        setTasks_(userDetails.tasks)
        setReviews_(userDetails.reviews)
    },[userDetails])

    useEffect(() => {
        async function getUser() {
            const user = await Jobin.Client.getById(props.match.params.userId);
            const chat = await searchWollerChat(props.match.params.userId);
            const homologation = await searchWollerDocuments(props.match.params.userId);
           
            if (user.status === "ok" && chat.status === "ok" ) {
                dispatch({
                    type: "USER_DETAILS",
                    user: user.result
                });
                dispatch({
                    type: "USER_DETAILS",
                    chat: chat.result
                });
                if(homologation.status === "ok" ){
                    dispatch({
                        type: "USER_DETAILS",
                        homologation: homologation.result
                    });
                }
                if (user.result.get("Jober")) {
                    await getJoberInfo(user.result);
                    await getJoberTasks(user.result);
                }
                await getPaymentMethods(user.result);
                await getBillingInfo(user.result);
                await getPayments(user.result);
            } else {
                props.history.goBack();
            }
            dispatch({
                type: "USER_DETAILS_LOADING",
                loading: false
            });
        }
        if(user.goldId){
            getUserData_()
        }else{
            getUser();
        }
    }, []);
    const getUserData_= async ()=>{
        try {
            let data = await getFullWollerProfile({wollerId:props.match.params.userId})
            if(data){
                let tasksParsed =   general.allGuilds.map(task_=>{
                    return  data.tasks.map(userTask_=>{
                        let taskType = userTask_.get("Type")
                        if(task_.name == taskType){
                            return task_
                        }
                    }).filter(x=>x)[0]
                }).filter(x=>x)
                dispatch({
                    type:"USER_DETAILS_SET_INFO",
                    reviews:data.reviews,
                    tasks:tasksParsed,
                    user:data.woller,
                    wollerData:data.wollerData,
                    wollerLocation:data.wollerLocation
                })
                dispatch({
                    type:"USER_DETAILS_LOADING",
                    loading:false
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getJoberInfo = async jober => {
        const info = await Jobin.Jober.getInfo(jober);
        if (info.status === "ok") {
            dispatch({
                type: "USER_DETAILS",
                info: info.result
            });
        } else {
            props.history.goBack();
        }
    };

    const getJoberTasks = async jober => {
        const tasks = await Jobin.Jober.getTasks(jober);
        if (tasks.status === "ok") {
            const tempArray = tasks.result.map(item => {
                return {
                    id: item.id,
                    name: item.get("Type")
                };
            });
            dispatch({
                type: "USER_DETAILS",
                tasks: tempArray
            });
        } else {
            props.history.goBack();
        }
    };

    const getPaymentMethods = async user => {
        const result = await Jobin.PayCard.getAllUserPayCards(user);
        if (result.status === "ok") {
            dispatch({
                type: "USER_DETAILS",
                paymentMethods: result.result
            });
        } else {
            props.history.goBack();
        }
    };

    const getBillingInfo = async user => {
        const result = await Jobin.Client.getBillingInfo(user.id);
        if (result.status === "ok") {
            dispatch({
                type: "USER_DETAILS",
                billingInfo: result.result
            });
        } else {
            props.history.goBack();
        }
    };

    const getPayments = async user => {
        const result = await Jobin.Payment.getPayments({ user });
        if (result.status === "ok") {
            dispatch({
                type: "USER_DETAILS",
                payments: result.result
            });
        } else {
            props.history.goBack();
        }
    };

    if (userDetails.loading) {
        return (
            <section style={style.loadingContainer}>
                <Loading />
            </section>
        );
    }  else if(user.goldId) {
        return (
            <div className="userProfile">
                <DetailsPro user={userDetails.user} userDetails={userDetails.wollerData} history={props.history} location={props.location}/>
                <Description 
                    user={user_}
                    userDetails={userDetails_} 
                    tasks={tasks_} 
                    reviews={reviews_} 
                    alltasks={general.allGuilds} 
                    history={props.history} 
                    location={props.location} 
                    wollerLocation={userDetails.wollerLocation}
                />
            </div>
        );
    } else {
        return (
            <section>
                <NavBar
                    history={props.history}
                    location={props.location}
                    title={"Detalle"}
                />
                <div className="detailContainer">
                    <Details
                        history={props.history}
                        location={props.location}
                    />
                    <NearJobs history={props.history} location={props.location} userDetails={userDetails} />
                    <UserCommets userDetails={userDetails} history={props.history} location={props.location}/>
                </div>
            </section>
        );
    }
}




