import React, { useState } from "react";
import Slider from "react-slick";

import MainModal from "./MainModal"

import Lottie from 'react-lottie'
import * as cardSpinnerData from "../../assets/animations/button-loading.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/modal/documents-modal.css";

const DocumentModal = ({ isVisible, onClose, documents, changeStateDocument }) => {
    const [isSavingChangesApproved, setIsSavingChangesApproved] = useState(false)
    const [isSavingChangesRejected, setIsSavingChangesRejected] = useState(false)
    const settings = {
        dots: true,
        accesibility: true,
        arrows:false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: cardSpinnerData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    return (

        <MainModal
            big={true}
            isVisible={isVisible}
            onClose={() => onClose()}
        >
            <h4 className="title">{documents.title}</h4>
            <div className="slider-container" >
            {documents.document.length > 1? (
                <Slider {...settings}>
                    {documents.document?.map(doc => {
                        return (<span><div className="document-image" style={{backgroundImage:"url("+doc.url()+")"}}></div> </span>)
                    })}
                </Slider>
            ):(
                <img className="document-image" src={documents.document[0].url()} alt={documents.type} srcset="" />
            )}
            </div>
            <div className="buttonsContainer">
                <button onClick={() => { setIsSavingChangesApproved(true);changeStateDocument(documents, "approved") }} className="aprove">{isSavingChangesApproved ? (<Lottie
                    style={{ height: 15, width: 40 }}
                    options={defaultOptions}
                    disabled={!isSavingChangesRejected}
                />) : "Aprobar"}</button>
                <button onClick={() => { setIsSavingChangesRejected(true);changeStateDocument(documents, "rejected") }} className="reject">{isSavingChangesRejected ? (<Lottie
                    style={{ height: 15, width: 40 }}
                    options={defaultOptions}
                    disabled={!isSavingChangesApproved}
                />) : "Rechazar"}</button>
            </div>
        </MainModal>
    )
}

export default DocumentModal;