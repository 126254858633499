import React, { useState, useEffect } from "react";
import Jobin from "jobin-client";
import { Button } from "jobin-ui-components";
import Parse from 'parse'
import fetchServices from "../../../constants/services";
import { useStateValue } from "../../../state/context";
import { apiId, jsKey, urlServer } from '../../../constants/credentials';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import InfoCell from '../../search/detail/InfoCell';
import ChatModal from '../../modal/ChatModal';
import StartRating from 'react-star-ratings';
import ShowMore from 'react-show-more-list';
import { editUserData, updateWollerActive, acceptCertificationFile, rejectCertificationFile, searchWollerDocuments } from "../../../utils/ParserUtilities.js";
import { formatDateWhitoutHour, formatStringPostalCodes } from "../../../utils/formatter"
import EditVerticalWollerModal from "../../modal/EditVerticalWollerModal"
import EditBusinessWollerModal from "../../modal/EditBusinessWollerModal"
import DocumentModal from "../../modal/DocumentModal"
import { getAddressByLatLong } from "../../../utils/reactGeocodeUtils"
import Info from './Info';
import Switch from "../../../components/singleComponents/switch";


import '../../../styles/search/detail/job-detail-container.css';
import EditPostalCodesModal from "../../modal/EditPostalCodesModal";
import EditNJobstModal from "../../modal/EditNJobsModal";

export default function Details(props) {

    const [{ user, userDetails, agent }, dispatch] = useStateValue();
    const [userEmail, setUserEmail] = useState('');
    const [userDNI, setUserDNI] = useState('');
    const [userBusiness, setUserBusiness] = useState(undefined);
    const [userAddress, setUserAddress] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [guildArr, setGuildArr] = useState([]);
    const animatedComponents = makeAnimated();
    const [stars, setStars] = useState();
    const [descriptionUser, setDescriptionUser] = useState();
    const [experienceYears, setExperienceYears] = useState();
    const [nWorkers, setnWorkers] = useState();
    const [displacementPrice, setDisplacementPrice] = useState();
    const [numberRating, setNumberRating] = useState(0);
    const [oneStar, setOneStar] = useState(0);
    const [twoStars, setTwoStars] = useState(0);
    const [threeStars, setThreeStars] = useState(0);
    const [fourStars, setFourStars] = useState(0);
    const [fiveStars, setFiveStars] = useState(0);
    const [listItems, setListItems] = useState([]);
    const [jobsType, setJobsType] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [showEditJobs, setShowEditJobs] = useState(false);
    const [showEditNJobs, setShowEditNJobs] = useState(false);
    const [showEditBussiness, setShowEditBussiness] = useState(false);
    const [showEditPostalCodes, setShowEditPostalCodes] = useState(false);
    const [showMessagesModal, setShowMessagesModal] = useState(false);
    const [showChatModal, setShowChatModal] = useState(true);
    const [checkActive, setCheckActive] = useState(false);
    const [showFullDocument, setShowFullDocument] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState();
    const isConstructionCompany = !user?.isGoldWoller && user?.isConstructionCompany;
    
    const style = {
        logo: {
            height: 48,
            width: 48,
            backgroundColor: "var(--soft-grey)",
            borderRadius: 24,
            overflow: 'hidden',
            objectFit: 'cover',
            marginRight: 12
        }
    }
    const levelOptions = [
        { label: "Bronce", value: 1 },
        { label: "Plata", value: 2 },
        { label: "Oro", value: 3 },
        { label: "Platino", value: 4 },
        { label: "Lvl 5", value: 5 },
        { label: "Sin competencia lvl 6", value: 6 }
    ]

    const canSeePCOptions = [
        {label:"No puede", value:false},
        {label:"Puede verlos en WollyPRO 2.0", value:true},
    ]

    useEffect(() => {
        getReview();
        async function fetchAsync() {
            setGuildArr(await fetchServices(userDetails.filters.services));
            setDescriptionUser(await userDetails?.info?.get("Description") ? userDetails.info.get("Description") : "")
            setExperienceYears(await userDetails?.info?.get("ExperienceYears") ? userDetails.info.get("ExperienceYears") + " años" : "")
            setnWorkers(await userDetails?.info?.get("EmployeesNumber") ? userDetails.info.get("EmployeesNumber") + " emplead@s" : "")
            setDisplacementPrice(await userDetails?.info?.get("DisplacementPrice") ? userDetails.info.get("DisplacementPrice") + " €" : "")
        }
        fetchAsync();
        getJobsType();
        getUserEmail();

        setShowChatModal(!isConstructionCompany);
    }, []);

    useEffect(() => {
        getJobsType();
    }, [setJobsType]);



    const getJobsType = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res = await Parse.Cloud.run("getTasks", { joberId: userDetails.user.id });
        setJobsType(res)
    }

    const getReview = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let data = {
            "joberId": userDetails.user.id
        }
        let res = await Parse.Cloud.run("getReview", data);
        let totalStars = 0;
        let index = 0;
        setNumberRating(res.length);
        let star1 = 0;
        let star2 = 0;
        let star3 = 0;
        let star4 = 0;
        let star5 = 0;

        res.forEach(async (element) => {
            totalStars += element.get("Stars");
            index++;
            let item = {
                id: index,
                title: element.get("Text"),
                message: element.get("Response")
            }
            if (listItems.length <= 10) {
                setListItems(state => [...state, item])
            }
            switch (element.get("Stars")) {
                case 1:
                    star1++;
                    setOneStar(star1)
                    break;
                case 2:
                    star2++;
                    setTwoStars(star2)
                    break;
                case 3:
                    star3++;
                    setThreeStars(star3)
                    break;
                case 4:
                    star4++;
                    setFourStars(star4)
                    break;
                case 5:
                    star5++;
                    setFiveStars(star5)
                    break;
                default:
                    break;
            }
        });

        let averageStars = totalStars / res.length;
        if (averageStars > 0) {
            setStars(averageStars);
        } else {
            setStars(0);
        }
    }

    const optionsSelectVerticals = guildArr.map(element => {
        return {
            id: element.id,
            value: element.id,
            label: element.name
        }
    });

    const verticalsSelected = userDetails.tasks.map(element => {
        return {
            id: element.id,
            value: element.id,
            label: element.name
        }
    })

    const handleChangeLevel = async (_data) => {
        let dataParsed = {
            key: "Level",
            value: _data.value
        }

        let response = await editUserData(dataParsed, userDetails.user.id)
    }
    const handleCanSeePC = async (_data)=>{
        let dataParsed={
            key:"canSeePC",
            value:_data.value
        }

        let response = await editUserData(dataParsed,userDetails.user.id)
    }
    const handleTextChange = async (event)=>{
        let dataParsed={
            key:event.target.name,
            value:event.target.value
        }
        let isWollerData
        if (event.target.name !== "email") {
            isWollerData = true
        } else {
            isWollerData = false
        }

        let response = await editUserData(dataParsed, userDetails.user.id, isWollerData)
    }
    const saveYearsChange = async (event) => {
        let dataParsed = {
            key: event.target.name,
            value: parseInt(event.target.value.replace(/[^0-9].*/, ""))
        }
        let isWollerData
        if (event.target.name !== "email") {
            isWollerData = true
        } else {
            isWollerData = false
        }

        let response = await editUserData(dataParsed, userDetails.user.id, isWollerData)
    }
    const handleNumberChange = (number) => {
        return number.replace(/[^0-9].*/, "")
    }
    const handleYearsChange = async (event) => {
        setExperienceYears(handleNumberChange(event.target.value) + " años")
    }
    const handleEmployeesNumberChange = async (event) => {
        setnWorkers(handleNumberChange(event.target.value) + " emplead@s")
    }
    const handlePriceDiscplacamentChange = async (event) => {
        setDisplacementPrice(handleNumberChange(event.target.value) + " €")
    }
    const handleChangeDescription = (event) => {
        setDescriptionUser(event.target.value)
    }
    const getUserEmail = async () => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let res
        if (userDetails.user.get("Jober")) {
            res = await Parse.Cloud.run("getWoller", { userId: window.location.hash.substring(7) });
            res = res[0];
        } else {
            res = await Parse.Cloud.run("getUserById", { id: window.location.hash.substring(7) });
        }
        if (res) {
            getAddressByLatLong(res.get("Location")?.latitude, res.get("Location")?.longitude, setUserAddress);
            setUserEmail(res.get("email"));
            setUserDNI(res.get("NIF"));
            setPostalCodes(res.get("postalCodes"));
            setUserPhone(res.get("Phone"));
            setUserBusiness(res.get("Business"))
            setCheckActive(res.get("Active"))
        }
    }
    const showModal = (_chats) => {
        setShowMessagesModal(true)
    }
    const checkMessages = (_chats) => {
        var newMessages = 0
        _chats.map((message) => {
            if (!message.read && message.user) {
                newMessages += 1
            }
        })
        return newMessages
    }
    const redirectToWollyRecharge = () => {
        window.open("https://jobin.es/comprar/", '_blank').focus();
    }
    const closeChatModal = (data) => {
        dispatch({
            type: "USER_DETAILS",
            chat: data.chat
        });
        setShowMessagesModal(false)
    }

    const changeCheckActive = async () => {
        setCheckActive(!checkActive);
        await updateWollerActive(userDetails.user.id, !checkActive);
    }
    const fullDocument = async (documents, type, title) => {
        setSelectedDocument({ document: documents, type: type, title: title });
        setShowFullDocument(true)
    }
    const changeStateDocument = async (document, status) => {
        if (status === "approved") {
            await acceptCertificationFile({ crmUserId: agent?.id, certificationId: userDetails?.homologation?.id, fileName: document.type });
        } else {
            await rejectCertificationFile({ crmUserId: agent?.id, certificationId: userDetails?.homologation?.id, fileName: document.type, reason: "verified via bi" });
        }
        const homologation = await searchWollerDocuments(userDetails.user.id);
        dispatch({
            type: "USER_DETAILS",
            homologation: homologation
        });
        setShowFullDocument(false)
    }
    const translateStatus = (status)=>{
        switch(status){
            case "approved":
                return "Aprobado"
                break
            case "rejected":
                return "Rechazado"
                break
            default:
                return "Pendiente de revision"
                break
        }
        
    }
    return (
        <div className="jobDetailColumn">
            <section className="jobDetailContainer">
                {showEditNJobs && (
                    <EditNJobstModal
                        isVisible={true}
                        onClose={() => setShowEditNJobs(false)}
                        userDetails={userDetails}
                    />
                )}
                {showEditJobs && (
                    <EditVerticalWollerModal
                        isVisible={true}
                        onClose={() => setShowEditJobs(false)}
                        jobsType={jobsType}
                        optionsSelectVerticals={optionsSelectVerticals}
                        joberId={userDetails.user.id}
                        setJobsType={setJobsType}
                    />
                )}
                {showEditBussiness && (
                    <EditBusinessWollerModal
                        isVisible={true}
                        onClose={() => setShowEditBussiness(false)}
                        name={userDetails.user.get("Name")}
                        userId={userDetails.user}
                        userBusiness={userBusiness}
                        setUserBusiness={setUserBusiness}
                    />
                )}
                {showEditPostalCodes && (
                    <EditPostalCodesModal
                        isVisible={showEditPostalCodes}
                        onClose={() => setShowEditPostalCodes(false)}
                        joberId={userDetails.user.id}
                        postalCodes={postalCodes}
                        setPostalCodes={setPostalCodes}
                        getUserEmail={getUserEmail}
                    />
                )}
                {showMessagesModal && (
                    <ChatModal
                        isVisible={showMessagesModal}
                        chat={userDetails.chat}
                        user={userDetails.user}
                        onClose={(chat) => closeChatModal(chat)}
                    />
                )}
                {showFullDocument && (
                    <DocumentModal
                        isVisible={showFullDocument}
                        documents={selectedDocument}
                        changeStateDocument={changeStateDocument}
                        onClose={() => setShowFullDocument(false)}
                    />
                )}
                <div style={{display: "flex", flex: 1, flexDirection: 'row', overflow: 'hidden', alignItems: 'center'}}>
                    <img style={style.logo} src={userDetails.user.get("UserPic") ? userDetails.user.get("UserPic").url() : require("../../../assets/user-default-pic.svg")} alt="smallUserPic"/>
                    <label className="title">{userDetails.user.get("Name")}</label>
                    {!user?.isGoldWoller && showChatModal && (<Button
                        style={{marginLeft:"auto",marginTop:"5px", height: 36, padding: 0, width: 110, backgroundColor: "#000000", borderColor: "#000000", color: "#FFE235" }}
                        buttonText={checkMessages(userDetails.chat) < 1 ?  "Chat":"+"+checkMessages(userDetails.chat)}
                        className = {checkMessages(userDetails.chat) < 1 ? "noMessages":"newMessage"}
                        onClick={ ()=>{showModal(); }}
                        />)}
                </div>
                <div style={{ display: "flex", flex: 1, flexDirection: 'row', overflow: 'hidden', alignItems: 'center' }}>
                    <img style={style.logo} src={userDetails.user.get("UserPic") ? userDetails.user.get("UserPic").url() : require("../../../assets/user-default-pic.svg")} alt="smallUserPic" />
                    <label className="title">{userDetails.user.get("Name")}</label>
                    {!user?.isGoldWoller && (<Button
                        style={{ marginLeft: "auto", marginTop: "5px", height: 36, padding: 0, width: 110, backgroundColor: "#000000", borderColor: "#000000", color: "#FFE235" }}
                        buttonText={checkMessages(userDetails.chat) < 1 ? "Chat" : "+" + checkMessages(userDetails.chat)}
                        className={checkMessages(userDetails.chat) < 1 ? "noMessages" : "newMessage"}
                        onClick={() => { showModal(); }}
                    />)}
                    {user?.isGoldWoller && (<Button
                        style={{ marginLeft: "auto", marginTop: "5px", height: 36, padding: 0, width: 150, backgroundColor: "#000000", borderColor: "#000000", color: "#FFE235" }}
                        buttonText={"Recargar Saldo"}
                        className={"noMessages"}
                        onClick={redirectToWollyRecharge}
                    />)}
                </div>
                <div className="detailContainer">
                    <InfoCell
                        title="ID del usuario"
                        data={userDetails.user.id}
                        halfLeft={true}
                    />
                    {!user?.isGoldWoller && user?.permissions?.user.edit ? (<div className="editable-info-cell right-cell">
                        <label style={{ color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width: "100%" }}>Nivel</label>
                        <Select
                            style={{ width: "100% !important" }}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            options={levelOptions}
                            component={animatedComponents}
                            defaultValue={levelOptions[userDetails.user.get("Level") - 1]}
                            onChange={handleChangeLevel}


                        />
                    </div>) : (<InfoCell
                        title="Nivel"
                        data={userDetails.user.get("Level") === 4 ? "Platino" : userDetails.user.get("Level") === 3 ? "Oro" : userDetails.user.get("Level") === 2 ? "Plata" : "Bronce"}
                        logo={userDetails.user.get("Level") === 4 ? require("../../../assets/badge-platinum.svg") : userDetails.user.get("Level") === 3 ? require("../../../assets/badge-gold.svg") : userDetails.user.get("Level") === 2 ? require("../../../assets/badge-silver.svg") : require("../../../assets/badge-bronce.svg")}
                        halfRight
                    />)}

                </div>
                <div className="detailContainer">
                    <div className="editable-info-cell left-cell">
                        <label style={{
                            color: '#B0B0B0',
                            fontSize: '12px',
                            marginTop: '8px',
                            width: "100%"
                        }}>Teléfono</label>
                        <input
                            disabled={user?.permissions && !user?.permissions?.user.edit}
                            className="textInput"
                            type="text"
                            style={{
                                width: '95%',
                                padding: '5px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px',
                                marginRight: "auto"
                            }}
                            defaultValue={userPhone}
                            onBlur={handleTextChange}
                            name="Phone"
                        ></input>
                    </div>
                    <div className="editable-info-cell right-cell">
                        <label
                            style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width: "100%"}}>Email</label>
                        <input
                            disabled={user?.permissions && !user?.permissions?.user.edit}
                            className="textInput"
                            type="text"
                            style={{
                                width: '95%',
                                padding: '5px',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px',
                                marginRight:"auto"
                        }} 
                        defaultValue={userEmail}
                        onBlur={handleTextChange}
                        name="email"
                        ></input>

                    </div>
                </div>
                <div className="detailContainer">
                    <div className="editable-info-cell left-cell">
                        <label style={{
                            color: '#B0B0B0',
                            fontSize: '12px',
                            marginTop: '8px',
                            width: "100%"
                        }}>DNI</label>
                        <input
                            disabled={user?.permissions && !user?.permissions?.user.edit}
                            className="textInput"
                            type="text"
                            style={{
                                width: '95%',
                                border: '1px solid #E3E3E3',
                                borderRadius: '4px',
                                marginRight: "auto"
                            }}
                            defaultValue={userDNI}
                            onBlur={handleTextChange}
                            name="NIF"
                        ></input>

                    </div>
                    {!isConstructionCompany && (
                    <div className="editable-info-cell right-cell">
                        <label style={{color: '#B0B0B0', fontSize: '12px', marginTop: '8px', width: "100%"}}>Ver precio cerrado</label>
                        <Select
                            style={{width:"100% !important"}}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            options={canSeePCOptions}
                            component={animatedComponents}
                            defaultValue={canSeePCOptions.find(option => option.value === userDetails.user.get("canSeePC"))}
                            onChange={handleCanSeePC}
                        />
                    </div>
                    )}
                </div>
                <div className="detailContainer">
                    <InfoCell
                        title="Fecha última conexión"
                        data={formatDateWhitoutHour(userDetails.user.get("Updated") ? userDetails.user.get("Updated").toString() : "Ningún registro")}
                        halfLeft={true}
                    />
                    <InfoCell
                        title="Fecha de registro"
                        data={formatDateWhitoutHour(userDetails.user.get("createdAt") ? userDetails.user.get("createdAt").toString() : "Ningún registro")}
                        halfRight
                    />
                </div>
                {userDetails.user.get("Jober") ? (
                    <div>
                        <div className="detailContainer">
                            <InfoCell
                                title="Nº de valoraciones"
                                    data={userDetails.user.get("Reviews")}
                                    halfLeft={true}
                                />
                                <InfoCell
                                    title="Puntuación"
                                    data={Number(userDetails.user.get("Points")).toFixed(2)}
                                    halfRight
                                />

                            </div>
                            <div className="detailContainer">
                                <InfoCell
                                    title="Dirección"
                                    data={userAddress}
                                />
                            </div>
                            <div className="detailContainer">
                                <InfoCell
                                    title="Saldo"
                                    data={userDetails.user.get("nJobs")}
                                />
                                {user?.permissions?.editNJobs &&
                                    <button onClick={() => setShowEditNJobs(true)} className='editJobTypesButton'>Editar
                                        Saldo</button>}
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <div style={{marginTop: 16, display: "flex", flexDirection: "column"}}>
                                    <p className="subTitle">Business:</p>
                                    <div style={{display: "flex", flexDirection: "row", marginTop: 10}}>
                                        <p style={{marginRight: 20}}>{userBusiness?.get("Name")}</p>
                                        {
                                            userBusiness?.get("Logo")?._url ? <img style={{height: 70, width: 90}}
                                                                                   src={userBusiness?.get("Logo")?._url}/> : undefined
                                        }
                                    </div>
                                </div>
                                {user?.permissions?.user.editBussiness && (
                                    <button onClick={() => setShowEditBussiness(true)}
                                            className='editJobTypesButton'>Editar</button>)}
                            </div>
                            {
                                user.permissions.changeWollerActive && (
                                    <div>
                                        <p style={{marginTop: 16, marginBottom: 15}} className="subTitle">Estado de
                                            Activación: </p>
                                        <Switch value={checkActive} getChangeValue={changeCheckActive}/>
                                    </div>
                                )
                            }
                            {!userDetails.loading &&  userDetails.homologation &&(<div>
                                <p style={{ marginTop: 16, marginBottom: 15 }} className="subTitle">Homologacion: {translateStatus(userDetails?.homologation?.state)}</p>
                                <div className="documentContainer" onClick={() => { fullDocument(userDetails.homologation?.NIF, "nif", "DNI o CIF") }}>
                                    <div className="imageContainer">
                                        {userDetails.homologation?.NIF?.map(document => {
                                            return (
                                                <img className="documentMini" src={document ? document.url() : null} />
                                            )
                                        })}
                                    </div>
                                    <p>DNI o CIF</p>
                                    <p>{translateStatus(userDetails.homologation?.documentState?.nif?.state)}</p>
                                </div>
                                <div className="documentContainer" onClick={() => { fullDocument(userDetails.homologation?.socialSecurity, "lastRecipe", "Póliza de Responsabilidad Civil") }}>
                                    <div className="imageContainer">
                                        {userDetails.homologation?.socialSecurity?.map(document => {
                                            return (
                                                <img className="documentMini" src={document ? document.url() : null} />
                                            )
                                        })}
                                    </div>
                                    <p>Póliza de Responsabilidad Civil</p>
                                    <p>{translateStatus(userDetails.homologation?.documentState?.lastRecipe?.state)}</p>
                                </div>
                                <div className="documentContainer" onClick={() => { fullDocument(userDetails.homologation?.lastRecipe, "socialSecurity", " Último recibo de Responsabilidad Civil") }}>
                                    <div className="imageContainer">
                                        {userDetails.homologation?.lastRecipe?.map(document => {
                                            return (
                                                <img className="documentMini" src={document ? document.url() : null} />
                                            )
                                        })}
                                    </div>
                                    <p> Último recibo de Responsabilidad Civil</p>
                                    <p>{translateStatus(userDetails.homologation?.documentState?.socialSecurity?.state)}</p>
                                </div>
                                <div className="documentContainer" onClick={() => { fullDocument(userDetails.homologation?.taxAgency, "taxAgency", "Certificado de obligaciones de la Seguridad Social") }}>
                                    <div className="imageContainer">
                                        {userDetails.homologation?.socialSecurity?.map(document => {
                                            return (
                                                <img className="documentMini" src={document ? document.url() : null} />
                                            )
                                        })}
                                    </div>
                                    <p>Certificado de obligaciones de la Seguridad Social</p>
                                    <p>{translateStatus(userDetails.homologation?.documentState?.taxAgency?.state)}</p>
                                </div>
                            </div>)}
                            {
                                !user.permissions.changeWollerActive && (
                                    <div>
                                        <p style={{marginTop: 16, marginBottom: 15}} className="subTitle">Estado de
                                            Activación: </p>
                                        <p>{checkActive ? "Activo" : "Desactivado"}</p>
                                    </div>
                                )
                            }
                            {
                                userDetails.user.get("Level") == 4 && (
                                    <>
                                        <div className="detailContainer">
                                            <InfoCell
                                                title="Códigos Postales"
                                                data={formatStringPostalCodes(postalCodes)}
                                            />
                                        </div>
                                        <button onClick={() => setShowEditPostalCodes(true)}
                                                style={{backgroundColor: 'white', marginTop: 5, border: "none"}}>
                                            <img style={{height: 20, width: 20,}} alt="img-button"
                                                 src={require("../../../assets/add.svg")}/>
                                        </button>
                                    </>
                                )
                            }
                            <div style={{marginTop: 16}}>
                                <div className='jobTypesWrapper'>
                                    <label className="subTitle">Verticales</label>
                                    {user?.permissions?.user.edit && (<button onClick={() => setShowEditJobs(true)}
                                                                              className='editJobTypesButton'>Editar</button>)}
                                </div>
                                {
                                    jobsType.map(job => (
                                        <div className={'jobTypes'}>
                                            {job.get('Type')}
                                        </div>
                                    ))
                                }
                            </div>
                            {userDetails.info &&
                                <div style={{marginTop: 16}}>
                                    <label className="subTitle">Información adicional</label>
                                    {!user?.isGoldWoller ? (<InfoCell
                                        title="Descripción"
                                        data={userDetails.info.get("Description")}
                                    />) : (<div className="display-flex flex-column">
                                        <label style={{
                                            color: '#B0B0B0',
                                            fontSize: '12px',
                                            marginTop: '8px'
                                        }}>Descripción</label>
                                        <textarea
                                            style={{
                                                width: '100%',
                                                padding: '5px',
                                                border: '1px solid #E3E3E3',
                                                borderRadius: '4px',
                                                minHeight: '80px',
                                                resize: 'none'
                                            }}
                                            value={descriptionUser}
                                            onChange={handleChangeDescription}
                                            onBlur={handleTextChange}
                                            name="Description"
                                        ></textarea>
                                    </div>)}
                                    <div className="detailContainer">
                                        {!user?.isGoldWoller ? (<InfoCell
                                            title="Años de experiencia"
                                            data={userDetails.info.get("ExperienceYears") + " años"}
                                            halfLeft={true}
                                        />) : (<div className="editable-info-cell right-cell">
                                            <label style={{
                                                color: '#B0B0B0',
                                                fontSize: '12px',
                                                marginTop: '8px',
                                                width: "100%"
                                            }}>Años de experiencia</label>
                                            <input
                                                className="textInput"
                                                type="text"
                                                style={{
                                                    width: '95%',
                                                    padding: '5px',
                                                    border: '1px solid #E3E3E3',
                                                    borderRadius: '4px',
                                                    marginRight: "auto"
                                                }}
                                                value={experienceYears}
                                                onBlur={saveYearsChange}
                                                onChange={handleYearsChange}
                                                name="ExperienceYears"
                                            ></input>

                                        </div>)}
                                        {!user?.isGoldWoller ? (<InfoCell
                                            title="Nº de trabajadores"
                                            data={userDetails.info.get("EmployeesNumber") + " emplead@s"}
                                            halfRight
                                        />) : (<div className="editable-info-cell right-cell">
                                            <label style={{
                                                color: '#B0B0B0',
                                                fontSize: '12px',
                                                marginTop: '8px',
                                                width: "100%"
                                            }}>Nº de trabajadores</label>
                                            <input
                                                className="textInput"
                                                type="text"
                                                style={{
                                                    width: '95%',
                                                    padding: '5px',
                                                    border: '1px solid #E3E3E3',
                                                    borderRadius: '4px',
                                                    marginRight: "auto"
                                                }}
                                                value={nWorkers}
                                                onBlur={saveYearsChange}
                                                onChange={handleEmployeesNumberChange}
                                                name="EmployeesNumber"
                                            ></input>

                                        </div>)}

                                    </div>
                                    <div className="detailContainer">

                                        {!user?.isGoldWoller ? (<InfoCell
                                            title="Precio por desplazamiento"
                                            data={userDetails.info.get("DisplacementPrice") + " €"}
                                            halfLeft={true}
                                        />) : (<div className="editable-info-cell right-cell">
                                            <label style={{
                                                color: '#B0B0B0',
                                                fontSize: '12px',
                                                marginTop: '8px',
                                                width: "100%"
                                            }}>Precio por desplazamiento</label>
                                            <input
                                                className="textInput"
                                                type="text"
                                                style={{
                                                    width: '95%',
                                                    padding: '5px',
                                                    border: '1px solid #E3E3E3',
                                                    borderRadius: '4px',
                                                    marginRight: "auto"
                                                }}
                                                value={displacementPrice}
                                                onBlur={saveYearsChange}
                                                onChange={handlePriceDiscplacamentChange}
                                                name="DisplacementPrice"
                                            ></input>

                                        </div>)}
                                    </div>
                                </div>
                            }
                            <div style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <label>Rating</label>
                                <label>{numberRating} valoraciones de clientes</label>
                                <StartRating
                                    rating={stars}
                                    starRatedColor="#ffc107"
                                    numberOfStars={5}
                                    starDimension="30px"
                                    name="rating"
                                />

                                <label style={{marginTop: "20px"}}>Comentarios:</label>
                                <ShowMore
                                    items={listItems}
                                    style={{marginTop: "20px"}}
                                    by={2}
                                >
                                    {({
                                          current,
                                          onMore,
                                      }) => (
                                        <React.Fragment>
                                            <ul style={{listStyle: "none"}}>
                                                <hr/>
                                                {current.map(item => (
                                                    <li
                                                        key={item.id}
                                                    >
                                                        <div>
                                                            <span>{item.title}</span>
                                                            <p>{item.message}</p>
                                                        </div>
                                                        <hr/>
                                                    </li>
                                                ))}
                                            </ul>
                                            <button
                                                disabled={!onMore}
                                                onClick={() => {
                                                    if (!!onMore) onMore();
                                                }}
                                                style={{
                                                    backgroundColor: "transparent",
                                                    border: "none",
                                                    color: "#2DA1DF"
                                                }}
                                            >
                                                Mostrar más
                                            </button>
                                        </React.Fragment>
                                    )}
                                </ShowMore>
                            </div>
                        </div>
                    ) : (
                        <div className="detailContainer">
                            <InfoCell
                                title="Nº trabajos publicados"
                                data={userDetails.user.get("Tasks")}
                                halfLeft={true}
                            />
                        </div>
                    )}
                    <Info history={props.history} location={props.location}/>
            </section>
        </div>
)
}