import React, { useState } from "react";
import { useStateValue } from "../../../../state/context";

import InfoCell from "../InfoCell";
import ButtonEmpty from "../../../button/ButtonEmpty";


import "../../../../styles/wolly.css";
import "../../../../styles/search/detail/job-detail-container.css";
let Parse = require("parse");

export default function ClientContainer() {
    const [data] = useStateValue();

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [hubspotLink, setHubspotLink] = useState(null);
    const [loading, setLoading] = useState(true);

    useState(async () => {
        try {
            const client = await Parse.Cloud.run("getUserById", { id: data.jobDetails.job.get("CreatedBy").id });
            setName(client.get("Name"));
            setPhone(client.get("Phone"));
            setEmail(client.get("email"));
            setHubspotLink(client.get("HubspotLink"));
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
      
    }, []);
   
    return (
        <section className="infoContainer">
            <div style={{display: "flex", flex: 1}}>
                <div style={{display: "flex", flex: 1}}>
                    <label className="title">Datos del cliente</label>
                </div>
                {hubspotLink && (
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end"
                        }}
                    >
                        <ButtonEmpty
                            buttonText="Ver en Hubspot"
                            onClick={() => {
                                window.open(hubspotLink, "_blank");
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="detailContainer  flex-justify-between">
                <InfoCell
                    title="Nombre del cliente"
                    data={loading ? "Cargando..." : name}
                    halfLeft
                />
                <InfoCell
                    title="Número de teléfono"
                    data={loading ? "Cargando..." : phone}
                    halfRight
                />
            </div>
            <div className="detailContainer  flex-justify-between">
                <InfoCell
                    title="Email"
                    data={loading ? "Cargando..." : email}
                    halfLeft
                />
            </div>
        </section>
    );
}
